.button {
  background-color: white;
  border: 2px solid lightseagreen;
  border-radius: 10%;
  color: lightseagreen;
  padding: 0.5% 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3%;
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
}
